<template>
  <div class="login-page">
    <!-- 背景图和logo -->
    <img class="bg-top" src="../../assets/images/login/bg-all.png" />
    <!-- <img class="bg-bottom" src="../../assets/images/login/bg-bottom.png" />
    <img class="logo" src="../../assets/images/login/logo.png" /> -->
    <div class="login-center">
      <!-- 表单 -->
      <div class="homeCenter">
        <div class="center">
          <div
            class="hclist"
            v-for="(i,index) in tabber"
            :key="index"
            @click="changeIdx(i.idx)"
            :class="cur == i.idx ? 'active':''"
          >{{i.name}}
          <span v-if="cur == i.idx" class="line"></span>
          </div>
        </div>
        <div v-if="cur == 1">
          <Form class="form" @submit="onSubmit">
            <Field class="field" placeholder="请输入账号" v-model="account" />
            <Field class="field" placeholder="请输入密码" v-model="password" type="password" />
            <router-link to="forgetPassword">
              <div class="forget-button">找回密码</div>
            </router-link>
            <Button class="login-button" native-type="submit">登录</Button>
          </Form>

          <!-- 用户协议 -->
          <div class="agreement-container">
            <Checkbox
              v-model="checked"
              shape="square"
              icon-size="13"
              checked-color="#CD011F"
            />
            <span class="agreement-label" @:click="toggleAgreement">登录即表示您同意</span>
            <router-link to="/plainText/agreement">
              <span class="agreement-button">《用户协议》</span>
            </router-link>
          </div>
        </div>
        <div v-if="cur == 2">
          <Form class="form" @submit="smsSubmit">
            <Field class="field" placeholder="请输入账号" v-model="phone" />
            <Field
              v-model="sms"
              class="field"
              clearable
              placeholder="验证码"
            >
              <template #button v-if="cur == 2">
                <div style="font-size: 14px;color: #CD011F;" v-if="isSend" @click="sendCode">发送验证码</div>
                <div v-else style="font-size: 14px;color: #CD011F;">
                  <span>{{num}}s</span>
                </div>
              </template>
            </Field>
            <Button class="login-button" native-type="submit" >登录</Button>
          </Form>

          <!-- 用户协议 -->
          <div class="agreement-container">
            <Checkbox
              v-model="checked"
              shape="square"
              icon-size="13"
              checked-color="#CD011F"
            />
            <span class="agreement-label" @:click="toggleAgreement">登录即表示您同意</span>
            <router-link to="/plainText/agreement">
              <span class="agreement-button">《用户协议》</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as user from '@/utils/user';
import { APP_ID } from '@/config';
import { Form, Field, Button, Checkbox } from 'vant';
import { handleLogin, fetchOpenId,handleSmsLogin,handleGetCode } from '@/api/login';
export default {
  components: {
    Form,
    Field,
    Button,
    Checkbox,
  },
  data() {
    return {
      openId: '',
      account: '',
      password: '',
      phone:'',
      sms:'',
      num: 60,
      timer: null,
      isSend: true,
      checked: false,
      tabber: [
        { name: "账号密码登录", idx: 1 },
        { name: "验证码登录", idx: 2 }
      ],
      cur: 1
    }
  },
  mounted() {
    if (!this.$route.query.code) {
      window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`);
    } else {
      this.getOpenId();
    }
  },
  methods: {
    // tabber切换
    changeIdx(index) {
      this.cur = index;
    },
    /**
     * 获取openid
     */
    async getOpenId() {
      const res = await fetchOpenId({ code: this.$route.query.code, appid: APP_ID });
      this.openId = res.data.openId;
    },

    /**
     * 提交表单
     */
    async onSubmit() {
      if (!this.checked) {
        this.$toast('请先勾选同意《用户协议》');
      } else {
        try {
          const res = await handleLogin({
            loginName: this.account,
            password: this.password,
            openId: this.openId || 'no_open_id',
          });
          user.login(res.data);
          this.$router.replace('/');
        } catch (e) {
          this.$toast(e.message);
        }
      }
    },
      /**
       * 短信验证码整的
       */
    async smsSubmit() {
          if(this.phone == undefined || this.phone == ""){
              this.$toast("账号不能为空");
              return;
          }
          if(this.sms == undefined || this.sms == ""){
              this.$toast("验证码不能为空");
              return;
          }
        if (!this.checked) {
            this.$toast('请先勾选同意《用户协议》');
        } else {
            try {
                const res = await handleSmsLogin({
                    loginName: this.phone,
                    validateLogin: this.sms,
                    openId: this.openId || 'no_open_id',
                });
                user.login(res.data);
                this.$router.replace('/');
            } catch (e) {
                this.$toast(e.message);
            }
        }
    },
    // 发送验证码
    async sendCode() {
        if(this.phone == undefined || this.phone == ""){
            this.$toast("用户账号不能为空");
            return;
        }
      const res = await handleGetCode({
        loginName: this.phone,
      });
      if(res.status == "2"){
          this.$toast(res.msg);
        this.isSend = false;
        this.timer = setInterval(() => {
          this.num--;
          if (this.num <= 0) {
            this.isSend = true;
            clearInterval(this.timer);
            this.num = 60;
          }
        }, 1000);
      }else{
        this.isSend = true;
        clearInterval(this.timer);
        this.num = 60;
      }
    },
    /**
     * 勾选用户协议
     */
    toggleAgreement() {
      this.checked = !this.checked;
    },
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  min-height: 100vh;
  // height: 812px;
  position: relative;
  // overflow: hidden;
  .bg-top {
    width: 100%;
    // position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    // height: 420px;
  }
  .bg-bottom {
    width: 100%;
    position: absolute;
    bottom: -9px;
    left: 0;
    z-index: -1;
  }
  .logo {
    width: 105px;
    height: 73px;
    margin-top: 113px;
  }
  .login-center{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 20px;
  }
  .homeCenter {
    // margin-top: 30px;
    background: #ffffff;
    border-radius: 15px 15px 0px 0px;
    .center {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin: auto;
      padding: 22px 25px 0 25px;
    }
    .hclist {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #818181;
      cursor: pointer;
      margin-right: 21px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
    }
    .line{
      width: 30px;
      height: 4px;
      background: #CD011F;
      display: inline-block;
      border-radius: 15px;
      margin-top: 6px;
    }
    .active {
      font-size: 18px;
      color: #2C2C2C;
      font-weight: 600;
      border-radius: 10px 0px 0px 0px;
    }
  }
  .form {
    // padding-top: 24px;
    .field {
      padding: 38px 24px 10px;
    }
    .field::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 24px;
      bottom: 0;
      left: 24px;
      border-bottom: 1px solid #EAEAEA;
      -webkit-transform: scaleY(.5);
      transform: scaleY(.5);
    }
    .forget-button {
      font-size: 14px;
      line-height: 20px;
      color: #CECECE;
      margin-top: 17px;
      width: 100%;
      text-align: right;
      padding-right: 24px;
      box-sizing: border-box;
    }
    .login-button {
      width: 327px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 12px;
      color: #FFFFFF;
      background: #CD011F;
      box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
      border-radius: 10px;
      border: none;
      margin-top: 51px;
    }
  }
  ::v-deep .van-checkbox__icon {
    width: 14px;
    height: 14px;
    .van-icon {
      border-radius: 3px;
      width: 14px;
      height: 14px;
      font-size: 11px;
    }
  }
  .agreement {
    &-container {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      margin-top: 17px;
      padding-left: 24px;
    }
    &-label {
      color: #CECECE;
      margin-left: 6px;
      user-select: none;
    }
    &-button {
      color: #CD011F;
    }
  }
}
</style>
